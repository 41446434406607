/**
 * @file This is for Alpine-related stuff!
 */

const organizerIdEvbToIcalFeedUrl = (organizerIdEvb: string) =>
  `${document.location.origin}/api/ical/eventbrite/${organizerIdEvb}`;
(window as any).organizerIdEvbToIcalFeedUrl = organizerIdEvbToIcalFeedUrl;

console.log("helpers.js loaded");

import Alpine from "alpinejs";

(window as any).Alpine = Alpine;

// document.addEventListener("DOMContentLoaded", (event) => {
// });
Alpine.start();
